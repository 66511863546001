<template>
  <section-wrapper
    :class="{
      'text-image__container--text-left': data.text_left,
      'text-image__container--background': data.text_image_background.data,
    }"
    class="text-image__container"
  >
    <div class="col">
      <figure v-if="data.text_image_image.data" class="figure__container">
        <picture class="figure__picture">
          <img
            :alt="data.text_image_image.data?.attributes.alternativeText"
            :src="useImageUrl(data.text_image_image, 'large')"
            class="picture__image"
          />
        </picture>
      </figure>
    </div>

    <div class="col col--content">
      <h2 class="section__title">
        {{ data.text_image_title }}
      </h2>

      <p class="section__subtitle gradient-text">
        {{ data.text_image_subtitle }}
      </p>

      <div class="section__content" v-html="data.text_image_content" />

      <div v-if="data.text_image_buttons.length" class="buttons__container">
        <app-button
          v-for="(button, i) in data.text_image_buttons"
          :key="`text-image-button-${i}`"
          :page="button.page?.data"
          :url="button.url"
          :variant="button.variant"
          class="buttons__single"
        >
          {{ button.text }}
        </app-button>
      </div>
    </div>
  </section-wrapper>
</template>

<script lang="ts" setup>
import { computed } from "#imports";
import AppButton from "~/modules/shared/components/AppButton.vue";
import SectionWrapper from "~/modules/shared/components/SectionWrapper.vue";
import type { TextImageComponentType } from "~/modules/shared/components/textImage/TextImage.types";
import { useImageUrl } from "~/modules/shared/composables/useImageUrl";

const props = defineProps<{
  data: TextImageComponentType;
}>();

const bgImage = computed(() => {
  if (props.data.text_image_background.data) {
    return `url(${props.data.text_image_background.data?.attributes.url})`;
  }

  return "";
});
</script>

<style lang="scss" scoped>
.text-image__container {
  :deep {
    .section-inner__container {
      @apply md:flex-row md:items-center;
    }
  }

  .col {
    @apply w-full md:w-1/2;

    &--content {
      @apply md:pl-52;
    }
  }

  .section__title {
    @apply text-title;
  }

  .section__subtitle {
    @apply mt-6 text-body;
  }

  .section__content {
    @apply mt-10 text-body-xs text-primary-400;
  }

  .buttons__container {
    @apply mt-14;
  }

  .buttons__single {
    @apply mb-8 xl:mb-0 mr-10;
  }

  &--text-left {
    .col--content {
      @apply md:pl-0 md:pr-52;
      order: -1;
    }
  }

  &--background {
    background: v-bind(bgImage);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;

    @screen md {
      padding: 21rem 0;

      :deep(.section-inner__container) {
        @apply py-0;
      }
    }

    @media screen and (max-width: theme("screens.md")) {
      background-image: none;
    }
  }
}
</style>
